
import {defineComponent} from 'vue';
// import Measure from "@/serviceProviders/model/Measure";
import ServiceProvider from "@/serviceProviders/model/ServiceProvider";
import {Trade} from "@/serviceProviders/model/trade";
import CustomButton from "@/common/button/CustomButton.vue";
import ConfirmationDialog from "@/common/ConfirmationDialog.vue";
import {serviceProviderStore} from "@/serviceProviders/model/currentServiceProviderKey";

interface Data {
  name: string,
  address: string,
  openingTimes: string,
  additionalInformation: string,
  serviceProvider: ServiceProvider | null,
  trades: Trade[] | null,
  selectedTrades: string[],
  inputDataValidity: ServiceProviderValidity,
  showConfirmationDialog: boolean,
  inputsTouched: boolean,
}

interface ServiceProviderValidity {
  isNameValid: boolean,
  isAddressValid: boolean,
  isOpeningTimesValid: boolean,
  isAdditionalInformationValid: boolean,
  isSelectedTradesValid: boolean,
}

export default defineComponent({
  components: {ConfirmationDialog, CustomButton},
  setup() {
    const currentServiceProviderKeyStore = serviceProviderStore();

    return { currentServiceProviderKeyStore }
  },
  data(): Data {
    return {
      name: '',
      address: '',
      openingTimes: '',
      additionalInformation: '',
      serviceProvider: null,
      trades: [],
      selectedTrades: [],
      inputDataValidity: {
        isNameValid: true,
        isAddressValid: true,
        isOpeningTimesValid: true,
        isAdditionalInformationValid: true,
        isSelectedTradesValid: true,
      },
      showConfirmationDialog: false,
      inputsTouched: false,
    };
  },
  watch: {
    'name'() {
      this.inputsTouched = true;
    },
    'address'() {
      this.inputsTouched = true;
    },
    'openingTimes'() {
      this.inputsTouched = true;
    },
    'additionalInformation'() {
      this.inputsTouched = true;
    },
    'selectedTrades'() {
      this.inputsTouched = true;
    },
  },
  methods: {
    async fetchAvailableTrades() {
      this.trades = await this.$ports.serviceProviders.getAvailableTrades() as Trade[];
    },
    async fetchServiceProvider() {
      this.serviceProvider = await this.$ports.serviceProviders.getServiceProviderByUserKey();
      if (this.serviceProvider !== null) {
        this.name = this.serviceProvider.name;
        this.address = this.serviceProvider.address;
        this.openingTimes = this.serviceProvider.openingTimes;
        this.selectedTrades = this.serviceProvider.tradeKeys;
        this.additionalInformation = this.serviceProvider.additionalInformation;
      }
    },
    validateInput(): boolean {
      this.inputDataValidity.isNameValid = this.name !== '';
      this.inputDataValidity.isAddressValid = this.address !== '';
      this.inputDataValidity.isOpeningTimesValid = this.openingTimes !== '';
      this.inputDataValidity.isAdditionalInformationValid = this.additionalInformation !== '';
      this.inputDataValidity.isSelectedTradesValid = this.selectedTrades.length >= 1;

      return this.inputDataValidity.isNameValid &&
          this.inputDataValidity.isAddressValid &&
          this.inputDataValidity.isOpeningTimesValid &&
          this.inputDataValidity.isAdditionalInformationValid &&
          this.inputDataValidity.isSelectedTradesValid;
    },
    async moveToServiceProviderProfile(serviceProviderKey: string) {
      await this.$router.push({
        name: 'service-provider-details',
        params: {
          key: serviceProviderKey,
        }
      });
    },
    async submitServiceProviderCreation() {
      if (!this.validateInput()) {
        return;
      }

      if (this.serviceProvider === null) {
        const newServiceProviderKey: string = await this.$ports.serviceProviders.registerServiceProvider({
          key: '',
          userKey: '',
          name: this.name,
          openingTimes: this.openingTimes,
          address: this.address,
          tradeKeys: this.selectedTrades,
          additionalInformation: this.additionalInformation,
        });
        this.currentServiceProviderKeyStore.updateCurrentServiceProviderKey(newServiceProviderKey);
        await this.moveToServiceProviderProfile(newServiceProviderKey);
      } else {
        await this.$ports.serviceProviders.updateServiceProvider({
          key: this.serviceProvider.key,
          userKey: '',
          name: this.name,
          openingTimes: this.openingTimes,
          address: this.address,
          tradeKeys: this.selectedTrades,
          additionalInformation: this.additionalInformation,
        });
        this.currentServiceProviderKeyStore.updateCurrentServiceProviderKey(this.serviceProvider.key);
        await this.moveToServiceProviderProfile(this.serviceProvider.key);
      }
    },
    displayConfirmationDialog() {
      if (this.inputsTouched) {
        this.showConfirmationDialog = true;
      } else {
        this.cancelServiceProviderCreation();
      }
    },
    async cancelServiceProviderCreation() {
      this.showConfirmationDialog = false;
      if (this.serviceProvider === null) {
        await this.$router.push({
          name: "user-role-select"
        });
      } else {
        await this.$router.back();
      }
    },
  },
  async created() {
    await this.fetchAvailableTrades();
    await this.fetchServiceProvider();
    this.inputsTouched = false;
  }
})
;
